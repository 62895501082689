import anylogger from '@app/anylogger'
import { IMenu, IMenuList, ITreeViewItem, ITreeViewProvider, TreeView, TreeViewApi, usePopupMenu } from '@app/muiplus'
import { parseUrl } from '@app/utils'
import AddIcon from '@mui/icons-material/Add'
import Archive from '@mui/icons-material/Archive'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { alpha, IconButton, ListItemIcon, Menu, MenuItem, styled, Theme, useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { usePermission } from 'src/services/usePermission'
import { IWebEditerServiceUI, useWebEditorServiceUI } from 'src/services/WebEditor/WebEditorServiceUI'
import { useCurrentRef } from '@app/hooks'

const log = anylogger('MenuTree')

interface MenuTreeProps {
	items: IMenuList
	onNavigate?: (menu: IMenu) => void
	onAddPage?: (menu: IMenu) => void
	onDeletePage?: (menu: IMenu) => void
	localStorageKey?: string
	fontSize?: string
}
export const MenuTree = React.forwardRef(function MenuTree(props: MenuTreeProps, ref: any) {
	const { items, onNavigate, onAddPage, onDeletePage, localStorageKey, ...rest } = props
	const router = useRouter()
	const { isWebEditor } = usePermission()
	const uiSvc = useWebEditorServiceUI()
	const [selected, setSelected] = useState('')
	const theme = useTheme()

	const [provider, setProvider] = useState<MenuTreeProvider>(new MenuTreeProvider([], false, uiSvc, theme))

	useEffect(() => {
		if (!router?.asPath) return

		// extract the base path, ignoring any url parameters
		const { pathname } = parseUrl(router.asPath)

		const item = provider.findNode(decodeURI(pathname))

		if (item?.id) {
			provider.expandNode(item.id)
			// provider.selectNode(item.id)
			setSelected(item.id)
		} else setSelected('')
	}, [provider, router.asPath])

	const itemSelected = (e: any, item: IMenu) => {
		if (onNavigate) onNavigate(item)
	}

	useEffect(() => {
		setProvider(new MenuTreeProvider(items, isWebEditor, uiSvc, theme))
	}, [isWebEditor, items, theme, uiSvc])
	return (
		<TreeView<IMenu, false>
			provider={provider}
			selected={selected}
			onItemSelected={itemSelected}
			restrictExpansionToIcon
			localStorageKey={localStorageKey}
			onlyShowEndIconOnHover={true}
			{...rest}
		/>
	)
})
class MenuTreeProvider implements ITreeViewProvider<IMenu> {
	root: IMenuList
	apiRef?: TreeViewApi
	isEditor: boolean
	refresh?: () => void
	uiSvc: IWebEditerServiceUI
	theme: Theme
	constructor(root: IMenuList, isEditor: boolean = false, uiSvc: IWebEditerServiceUI, theme: Theme) {
		this.root = root
		this.isEditor = isEditor
		this.uiSvc = uiSvc
		this.theme = theme
		this.assignIds(root, '')
	}
	setApi(apiRef: TreeViewApi, refresh: () => void): void {
		this.apiRef = apiRef
		this.refresh = refresh
	}
	assignIds(items: IMenuList, parent: string) {
		items.forEach((child) => {
			child.id = parent + '/' + child.caption
			if (child.children) this.assignIds(child.children, child.id)
		})
	}
	addClick(e: any, item: IMenu) {
		e.stopPropagation()
		this.uiSvc.addPage(item.target)
	}
	getMenuIcon(item: IMenu) {
		return <ActionMenuButton item={item} />
	}
	getAddIcon(item: IMenu) {
		return (
			<CustomIconButton size="small" onClick={(e) => this.addClick(e, item)}>
				<AddIcon color="inherit" sx={{ fontSize: 'inherit' }} />
			</CustomIconButton>
		)
	}
	getIcons(item: IMenu) {
		if (!this.isEditor) return null
		if (!item.options?.isEditable) return null
		return (
			<>
				{!Boolean(item.children?.length) && this.getMenuIcon(item)}
				{Boolean(item.children?.length) && this.getAddIcon(item)}
			</>
		)
	}
	getChildren(item?: IMenu): Promise<ITreeViewItem[]> {
		const children = (item ? item.children : this.root) ?? []
		const res = children.map((child) => {
			const level = (child.id!.match(/\//g) || []).length
			const sxProps =
				level == 1
					? { fontWeight: 'bold', color: this.theme.palette.primary.main, fontSize: '110%' }
					: level == 2 && child.children?.length
						? { color: this.theme.palette.primary.main }
						: {}
			return {
				source: child,
				id: child.id!,
				label: child.caption,
				endIcon: this.getIcons(child),
				sxProps
			}
		})

		return Promise.resolve(res)
	}
	findNode(path: string, parent?: IMenuList): IMenu | undefined {
		if (!parent) parent = this.root
		for (const child of parent) {
			if (child.target === path) return child
			if (child.children) {
				const res = this.findNode(path, child.children)
				if (res) return res
			}
		}
		return
	}
	expandNode(id: string) {
		if (!this.apiRef?.current) return
		const dirs = id.split('/').filter((dir) => dir != '')
		let path = ''
		dirs.forEach((dir) => {
			path += '/' + dir
			// @ts-ignore
			this.apiRef.current.setItemExpansion(undefined, path, true)
		})
	}
	selectNode(id: any) {
		if (this.apiRef?.current)
			this.apiRef.current.selectItem({
				// @ts-ignore
				event: undefined,
				itemId: id,
				keepExistingSelection: false,
				shouldBeSelected: true
			})
	}
}
const CustomIconButton = styled(IconButton)(({ theme }) => ({
	// padding: '0px',
	height: '1em',
	width: '1em',
	'&:hover': {
		// we are using selectedOpacity to distinguish from the item which the button is on, which already has hover opacity
		backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
	}
}))

interface ActionMenuButtonProps {
	item: IMenu
}
const ActionMenuButton = function ActionMenuButton(props: ActionMenuButtonProps) {
	const { item } = props
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const open = Boolean(anchorEl)

	const [ref, setRef] = useCurrentRef<HTMLButtonElement>()
	const uiSvc = useWebEditorServiceUI()

	const deletePage = useCallback(() => {
		uiSvc.deletePage(item.target)
	}, [item.target, uiSvc])
	const archivePage = useCallback(() => {
		uiSvc.archivePage(item.target)
	}, [item.target, uiSvc])

	const showMenu = useCallback((e: any) => {
		e.stopPropagation()
		setAnchorEl(e.target)
	}, [])
	const onClose = useCallback(() => {
		setAnchorEl(null)
	}, [])
	return (
		<>
			<Menu anchorEl={ref} open={open} onClose={onClose}>
				<MenuItem onClick={deletePage}>
					<ListItemIcon>
						<DeleteIcon color="error" fontSize="small" />
					</ListItemIcon>
					Delete Page
				</MenuItem>
				<MenuItem onClick={archivePage}>
					<ListItemIcon>
						<Archive color="warning" fontSize="small" />
					</ListItemIcon>
					Archive Page
				</MenuItem>
			</Menu>
			<CustomIconButton ref={setRef} size="small" onClick={showMenu}>
				<MoreHoriz color="inherit" sx={{ fontSize: 'inherit' }} />
			</CustomIconButton>
		</>
	)
}
