import { Flex, useBreakpoint, useModal, usePopupMenu, Yes } from '@app/muiplus'
import { Avatar, Button, Tooltip } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { signIn, signOut } from 'next-auth/react'
import { useSession } from 'src/services/useSession'
import anylogger from '@app/anylogger'
import { useRouter } from 'next/router'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useCurrentRef } from '@app/hooks'
import { sendDebugEmail } from './SendErrorReport'

const log = anylogger('SessionStatus')

export const SessionStatus = function SessionStatus(props: any) {
	const [tooltip, setTooltip] = useState('')

	const router = useRouter()

	const [session, loading] = useSession()
	useEffect(() => {
		if (!session) return setTooltip('Login to access member content')
		setTooltip(`${session.user?.name} (${session.user?.email})`)
	}, [session])
	const { yesNoPrompt, alertDialog, busyDialog } = useModal()

	const logOut = useCallback(async () => {
		const res = await yesNoPrompt({
			title: 'Are you sure you want to log out?',
			content: (
				<>
					<div>There is no need to log out as you are only remembered on this computer or device.</div>
					<br />
					<div> Staying logged will allow you instantly access member content when you come back to the site.</div>
					<br />
					<div>
						If you do log out, you will have to enter your membership number and then email, and then enter the code that is
						sent to you by email.
					</div>
					<br />
					<Flex justifyContent="center">Are you sure you want to log out?</Flex>
				</>
			),
			preserveContentWhitespace: true,
			sx: { maxWidth: '600px' }
		})
		if (res != Yes) return
		signOut({ callbackUrl: '/' })

		return true
	}, [yesNoPrompt])
	const logIn = useCallback(
		(context: any) => {
			router.push('/auth/Login')
			return true
		},
		[router]
	)
	const [profileRef, setProfileRef] = useCurrentRef<HTMLElement>()

	const { showPopup, createMenuItem } = usePopupMenu()

	const sendDebugLog = useCallback(async () => {
		const email = session?.user?.email
		const name = session?.user?.name

		const { cancel } = busyDialog({ title: 'Sending Debug Email', content: 'Sending...' })
		try {
			await sendDebugEmail(name || '', email || '')

			await cancel()
			alertDialog({
				title: 'Email Sent',
				content: 'The email was sent successfully.'
			})
		} catch (err) {
			await cancel()
			log('Error sending debug email', err)
			alertDialog({
				title: 'Error Sending Debug Email',
				content: JSON.stringify(err)
			})
		}
	}, [alertDialog, busyDialog, session?.user?.email, session?.user?.name])

	const imageClick = useCallback(
		(e: any) => {
			showPopup([createMenuItem('Send Debug Logs', sendDebugLog), createMenuItem('Logout', logOut)], profileRef, 'BottomLeft')
		},
		[createMenuItem, logOut, profileRef, sendDebugLog, showPopup]
	)

	if (loading) return null
	if (session)
		return (
			<Tooltip title={tooltip} placement="top">
				<Flex
					ref={setProfileRef}
					alignItems="center"
					onClick={imageClick}
					gap="0px"
					sx={{
						cursor: 'pointer'
					}}
				>
					{/* @ts-ignore */}
					{/* {<Avatar size="sm" src={session?.user?.image || <AccountCircle />} />} */}
					{session?.user?.image ? (
						<Avatar src={session?.user?.image} />
					) : (
						<Avatar
							sx={{
								fontSize: '2em',
								bgcolor: 'primary.main'
							}}
						>
							<AccountCircle fontSize="inherit" />
						</Avatar>
					)}
				</Flex>
			</Tooltip>

			// <Button onClick={logOut} tooltip={tooltip}>
			// 	Logout
			// </Button>
		)
	return (
		<Button onClick={logIn} tooltip={tooltip}>
			Login
		</Button>
	)
}
